/* styles.css */
html {
    zoom: 85%; /* Ajusta el zoom al 80% */
}

.modal-backdrop {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 998;
    zoom: 117.65%; 
}

.modal-fullscreen {
    zoom: 117.65%;
    .modal-content {
        transform: scale(0.85);
        transform-origin: top left;
        width: 117.65%;
        height: 117.65%;
    }
}

