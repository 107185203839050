.react-daterange-picker__wrapper {
    border: thin solid #ccc;
    border-radius: 5px;
}

.vl {
    border-left: 2px solid #ccc;
    height: 240px;
    position: relative;
    left: 50%;
    margin-left: -3px;
    top: 0;
}

.vl2 {
    border-left: 2px solid #ccc;
    height: 100px;
    position: relative;
    left: 50%;
    top: 0;
}